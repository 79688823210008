.service-issues-form {
  .service-support-form {
    background-color: #f4f4f4;
    padding: 30px 0;
  }

  .heading {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
  }

  .form-control-textarea {
    width: 100%;
    border-radius: 3px;
    height: 100px;
    border: 1px solid #d1d1d1;
    padding: 5px 10px;
  }

  .optional {
    margin: 0 5px;
  }

  .disclaimer {
    padding: 5px 7px
  }

  .thankyou-wrap {

    p {
      font-size: 16px;
    }

    a {
      margin: 0px 5px;
    }
  }

  .message-wrap {

    p {
      font-size: 16px;
      margin-top: 25px;
    }

    a {
      margin: 0px 5px;
    }
  }

  .jobnumber-input {
    text-transform: uppercase;
  }
}

[data-brand="lhss"],
.lhss-estimate-forms {
  .service-support-form {
    background: #fff;
  }
}

[data-brand="lhws"] {
  .leaffilter-forms {
      .service-support-form {
        background: #fff;

        label {
          font-weight: 700;
        }
    }
  }
}
