.single-step-estimate {
  .grecaptcha-badge { visibility: hidden; }

  .MuiPaper-elevation1 {
    margin: 0;
    width: 100%;
  }

  .MuiContainer-root {
    padding-left: 0;
    padding-right: 0;
  }

  .recaptcha {
    margin-bottom: 20px;
  }
}
