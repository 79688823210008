.i-referred-form {
  margin: 40px 0;
  .i-referred {
    position: relative;

    .choice {
      &__banner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        color: #fff;
        background: #00853e;
        margin: 0;
        padding: 1rem 0;
      }

      &__bottom {
        text-align: center;
        border: 1px solid #d1d1d1;
      }

      &__button {
        color: #00853e;
        background: white;
        border-radius: 3px;
        border: 0;
        border: 1px solid #00853e;
        box-shadow: none;
        padding: 1rem 2rem;
        margin: 2.5rem auto;
      }
    }
  }

  .thank-you-interest {
    display: block;
    @media only screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 6rem;
      align-items: start;
    }

    div:first-child {
      grid-row: 1;
      grid-column: 1;
      margin-right: 4rem;

      @media only screen and (max-width: 767px) {
        margin-right: 0;
      }
    }

    div:last-child {
      grid-row: 1;
      grid-column: 2;
    }

    &__form-header {
      font-weight: 700;
      margin: 1.5rem 0;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        margin-bottom: 0.5em;
        position: relative;

        svg {
          width: 28px;
          padding-right: 10px;
        }
      }
    }
  }

  .section-header {
    margin-bottom: 20px;
    text-align: center;
  }

  .image-content {
    margin-top: 2rem;

    &__copy {
      font-size: 16px;
      margin: 20px 0;
    }
  }

  .referral__container--fluid {
    width: 80%;
    margin: 0 auto;
  }

  .lock-icon {
    position: relative;

    svg {
      width: 24px;
      padding-right: 10px;
      padding-bottom: 4px;
    }

    a {
      text-decoration: none;
    }
  }

  .social {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;

    a {
      position: relative;
      text-decoration: none;
      width: 65px;

      .social-link {
        border: 2px solid #e8e8e8;
        border-radius: 50%;
        text-align: center;
        margin-bottom: 10px;
        height: 65px;

        .custom-img {
          width: 30px;
          text-align: center;
          margin: 0;
        }
      }

      p {
        line-height: 1.6;
        text-decoration: none;
        color: #000;
        font-size: 16px;
        text-align: center;
      }
    }
  }

  .centered {
    text-align: center;
  }
}
