.leaffilter-forms {
  form,
  .render-owner-thankyou {
    scroll-margin-top: 150px;

    @media only screen and (max-width: 768px) {
      scroll-margin-top: 100px;
    }
  }

  label {
    color: #00853e;
    font-weight: 600;
  }

  .thankyou-B,
  .thankyou-C {
    .step5-main-text {
      text-align: center;
    }
  }

  .input-field {
    width: 100%;
    border-radius: 3px;
    height: 40px;
    border: 1px solid #d1d1d1;
    padding: 5px 10px;

    &.error {
      border: 1px solid #ef643d !important;
    }

    &.correct {
      border: 1px solid #00853e !important;
    }
  }

  .error-msg,
  .error-msgs {
    color: #c90000;
    font-weight: 600;
    margin-bottom: 0;
  }

  p {
    font-size: 12px;
    text-align: left;
  }

  .spacer {
    margin: 5px;
  }

  .success {
    background-color: #00853e;
  }

  .incomplete {
    background-color: #e8e8e8 !important;
  }

  .custom-img {
    width: 65px;
    height: 65px;
    margin: 10px 10px 0 8px;
  }

  .custom-img.with-border {
    border-radius: 10px;
    border: 2px solid #94ca5f;
    background: #00853e;

    path {
      stroke: none;
      fill: #00853e;
    }

    .icon-outline,
    .st42 {
      fill: white;
    }
  }

  .podcast-img {
    border: 2px solid #767777;
    border-radius: 10px;
  }

  .custom-img.with-border.podcast-img {
    path.st7 {
      fill: white;
    }
  }

  .ad-level-img {
    width: 65px;
    height: 65px;
  }

  .ad-level-img.with-border {
    border-radius: 10px;
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 16px 0 !important;
    background-color: #d1d1d1;
    opacity: .5;
  }

  .step1 {
    .area-info {
      background-color: #e8e8e8;
    }
  }

  .step2 {
    display: flex;
    align-items: center;
  }

  .discount-text {
    color: #006d9e;
    font-weight: 600;
  }

  .ad-level-text {
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }

  .ad-level-text-streaming {
    font-weight: 600;
    font-size: 11px;
    color: #333;
  }

  .ad-images-container {
    label {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .step5-main-text {
    font-weight: 700;
    font-size: 20px;
    color: #00853e;
    ;
  }

  .step5-sub-text {
    font-weight: 400;
    font-size: 16px;
    color: #333;

    u {
      cursor: pointer;
    }
  }

  .phone-number {
    color: #00853e;
    cursor: pointer;
  }

  .phone-icon {
    rotate: 270deg;
    width: 16px;
    height: 16px;
  }

  .form-control-textarea {
    width: 100%;
    border-radius: 3px;
    height: 100px;
    border: 1px solid #d1d1d1;
    padding: 5px 10px;
  }

  .optional {
    color: rgba(136, 136, 136, .85);
    font-size: 15px;
  }

  .MuiPaper-elevation1 {
    padding: 16px 64px !important;
    border-radius: 25px !important;
    height: auto;

    @media only screen and (max-width: 768px) {
      padding: 16px !important;
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .image-wrapper {
    label {
      flex-direction: column;
    }

    &-label-text {
      color: #333;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 5px;

      &.selected {
        color: #00853e;
      }
    }
  }

  .recaptcha {
    margin-bottom: 20px;
  }

  .field-container,
  .optional-container {
    display: block;
    margin-bottom: 24px;
  }

  .alert-success {
    color: #00853e;
    border: 2px solid #00853e;
    padding: 10px;
  }

  .link-button {
    border: none;
    background: transparent;
    text-decoration: underline;
  }

  .service-type,
  .roof-shape {
    .with-border {
      path {
        &.cls-1 {
          fill: white;
        }
      }
    }

    .podcast-img {
      border: none;

      &.with-border {
        border: 2px solid #94ca5f;
      }
    }
  }
}

.MuiDialog-container {
  .MuiDialog-paperScrollBody {
    max-width: 425px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);

    .leaffilter-forms {
      .MuiPaper-elevation1 {
        padding: 20px !important;
        border-radius: 8px !important;

        @media only screen and (max-width: 768px) {
          padding: 16px !important;
        }
      }
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}

.position-cursor {
  cursor: pointer;
}
