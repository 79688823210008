.referral-form {
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    list-style: none;
    margin: 0;
    padding: 0;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10rem;

    &.IReferred,
    &.ReferAFriend {
      display: block;
    }
  }
}
