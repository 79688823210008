.leaffilter-forms.privacy-form {
    label {
      color: black;
      font-weight: 600;
    }

    .text-editor .validation-icon {
      margin-top: -25px;
    }

}
