.single-step-estimate {
  .error-msgs {
    color: #c90000;
    font-weight: 600;
    margin: 0 !important;
  }

  .optional {
    color: rgba(136, 136, 136, .85);
  }
  .grecaptcha-badge { visibility: hidden; }

  .MuiPaper-elevation1 {
    margin: 0;
    width: 100%;
  }

  .MuiContainer-root {
    padding-left: 0;
    padding-right: 0;
  }

  .recaptcha {
    margin-bottom: 20px;
  }
}
