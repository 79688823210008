/**
* Css for LHSS multistep form.
**/
[data-brand="lhss"],
.lhss-estimate-forms {
  .lhss-estimate-forms,
  &.leaffilter-forms {

      .MuiPaper-elevation1 {
        border-radius: 0px!important;
      }

      .product-group-fieldset {
        display: grid;
        grid-template-columns: 1fr 1fr 31px;
        justify-items: left;
        align-items: baseline;
        max-width: 220px;
        width: 100%;

        label {

          span {
            text-align: center;
            line-height: 1.2;
            margin-top:5px;
        }
      }
    }

    .st38 {
      stroke-width: 0px;
    }

    .promo-title {
      color: #3d7491;
      border: 0;
      padding: 7px 12px 6px;
      font-weight: 700;
      text-align: center;
      font-size: 16px;
    }

    .phone-number {
      text-decoration: underline;
    }

    .ad-images-container label {
      margin-left: 0px;
      margin-right: 0px;
    }

    .custom-img {
      width: 70px;
      height: 70px;

      &.with-border {

          path {
              fill: #0a4256;
              stroke: #8ea9b9;
          }

          .st42 {
              fill: none;
          }

          .icon-outline {
              fill: #fff;
              stroke: #fff;
              stroke-width: 0px;
          }
      }
    }
  }
}
