[data-brand=lhws],
.lhws-estimate-forms {
  .leaffilter-forms,
  &.leaffilter-forms {
    .lf-icon-control-label {
      transition: all 0.3s;
      vertical-align: top;
      border: 1px solid rgba(223, 223, 223, 0);

      &.selected-border {
        border-color: #000;
      }
    }


    .MuiPaper-elevation1 {
      border-radius: 0 25px 25px 0 !important;
    }

    .image-wrapper-label-text {
      font-size: 12px;
      font-weight: 700;
      line-height: 1.4;
      padding-top: 6px;
      transition: all 0.3s;

      &.selected {
        color: #1d2d36;
      }
    }

    fieldset {
      label {
        font-weight: 700;
        margin: 0 0 14px 0;
      }
    }

    .label-bold label {
      font-weight: 700;
      padding: 0;
    }

    .discount2-title {
      color: #1d2d36;
      font-size: 1.5625rem;
      font-weight: 400;
      line-height: 1.2;
    }

    .discount-text  {
      color: #1d2d36;
      font-size: 1.25rem;
      font-weight: 400;

      ul {
        list-style-type: none;
        padding-left: 0;
      }
    }

    .phone-number {
      cursor: pointer;
      padding-top: 22px;
      position: relative;
      font-weight: 600;
      color: #333333;
    }

    .thankyou-wrap {
      line-height: 29.143px;

      ul {
        .bullet-icon {
          width: 20px;
          height: 20px;
          position: absolute;
          margin-left: -30px;
        }
      }

    }

    p {
      font-size: 1rem;
    }

    u {
      text-decoration: none;
    }

    // Version 2 styling.
    &.version-2 {

      label {
        &.version-2-color {
          color: #00853e !important;
        }
      }
      .custom-img {
        margin: 0;
        border: 2px solid transparent;
        width: 85px;
        height: 85px;
      }

      .lf-icon-control-label {
        margin: 0 4px;

        &:first-child {
          margin-left: 0;
        }

        &::after {
          content: "✔" !important;
          width: 40px;
          height: 40px;
          overflow: hidden;
          border-radius: 50%;
          pointer-events: none;
          opacity: 0;
          transition: all .3s;
          text-align: center;
          line-height: 40px;
          color: #fff;
          display: block;
          position: absolute;
          top: 38%;
          background-color: #00853e;
          left: 0;
          right: 0;
          margin: -20px auto;
        }

        &.lf-icon-control-label {
          border: none;
          position: relative;
        }

        &.selected-border {
          .custom-img {
            border-radius: 12px;
            border-color: #00853e;
          }

          .image-wrapper-label-text {
            &.selected {
              color: #00853e;
            }
          }

          &::after {
            opacity: 0.9;
          }
        }

        .image-wrapper-label-text {
          font-size: 11px;
        }
      }

      .form-step-title {
        font-size: 25px;
        line-height: 1.3;
        font-weight: 700;
      }

      p {
        font-size: 18px;
        margin-bottom: 10px;

        &.form-disclaimer,
        &.error-msgs,
        &.error-msg {
          font-size: 14px;
          margin: 0 auto;
          line-height: 1.8;
        }

        &.form-step-desc {
          line-height: 25px;
        }
      }

      label {
        color: #333333 !important;
        font-weight: 600;
      }

      .input-field.error {
        border: 1px solid #c02b0a !important;
      }

      .btn {
        border-radius: 0;
        font-size: 15px;
        padding: 15px 30px;
      }

      .thankyou-wrap {
        h2 {
          font-size: 24px;
          line-height: 28px !important;
          font-weight: bold !important;
        }
      }

      .discount2-title {
        font-size: 24px;
        color: #333;
        font-weight: 500;
      }

      .discount-text {
        font-size: 24px;
        color: #333;

        .fw-normal {
          font-weight: 500 !important;
        }
      }

      #txtSMSMarketingConsent {
        + label {
          + p {
            line-height: 1.73;
            font-size: 15px;

            a {
              text-decoration: underline;
            }
          }
        }
      }

      .adLevel {
        margin-top: 14px;

        > label {
          margin: 10px 10px 0 8px;
        }
      }

      .tel-link .btn-tertiary {
        background-color: #00853e;
        border-color: #00853e;
        width: auto !important;
      }

      .step5-sub-text {
        .phone-number {
          color: #02A780;
        }
      }
    }
  }
}
