.zipchecker-form {
  max-width: 360px;
  margin: 0 auto;

  .field-container {
    margin-bottom: 10px;
  }

  .btn-estimate {
    box-shadow: none;
    width: auto;
    display: block;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 10px;
    height: 40px;
    text-shadow: none;
    text-transform: capitalize;
    white-space: normal;
    border-radius: 3px;
    border: #ef643d;
    top: 2px;
    right: 2px;

    &,
    &:hover,
    &:active,
    &:focus,
    &:visited {
      color: #ffffff;
    }

    &:focus {
      border-color: #00853e;
    }
  }

  .input-field {
    width: 100%;
    border-radius: 3px;
    height: 44px;
    border: 1px solid #fff;
    padding: 5px 10px;
    outline: 0;

    &::placeholder {
      color: #888888;
      opacity: 0.7;
    }
  }

  .error-msgs {
    color: #c90000;
    background-color: #ffffff;
    font-size: .9em;
    position: relative;
    border: 1px solid #d1d1d1;
    padding: 12px;
    display: inline-block;
    margin-top: 5px;
    position: absolute;
    left: 0;
    font-weight: 600;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
      position: absolute;
      top: -6px;
      left: 10px;
    }
  }
}
