.leaffilter-forms {
  .grecaptcha-badge {
    visibility: hidden;
  }

  .recaptcha {
    margin-bottom: 20px;
  }
  .LFRentOwnHide {
    display: none;
  }
}

.leafhome-forms {
  .progress {
    --bs-progress-height: 4px;
  }

  .progress-bar {
    height: 4px;
  }

  .spacer {
    margin: 5px;
  }

  .incomplete {
    background-color: #DEE2E6 !important;
  }

  .form-icon {
    svg {
      background: #E9ECEF;
      border-radius: 10px;
      border: 1px solid #E9ECEF;

      &.with-border {
        display: none;
        + svg {
          border: 1.5px solid #10260F;
          display: block;
        }
      }

      &.hide {
        display: none;
      }
    }
  }

  .step-4 {
    label {
      &.form-icon {
        margin-bottom: 12px;
      }
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}

.position-cursor {
  cursor: pointer;
}
