.leafhome-zipchecker-form {
  max-width: 360px;

  .input-field {
    margin-bottom: 14px;
  }

  .field-container {
    position: relative;
  }

  .error-msg {
    margin-bottom: 8px !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 136px;
    background: #000;
    padding: 4px 8px;
    width: 100%;
    max-width: 200px;
    font-size: 14px;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }

  p.error-msg {
    color: #fff;
    text-align: left;
  }

  .validation-icon {
    position: absolute;
    right: 10px;
    top: 14px;
    margin-left: 0 !important;
  }
}
