[data-brand=lhss],
.lhss-estimate-forms {

    .leaffilter-forms,
    &.leaffilter-forms {
        label {
            color: #3d7491;
            font-weight: 600;
        }

        .custom-img {
            width: 70px;
            height: 70px;

            &.with-border {
                background: #0a4256;
                border: 2px solid #3d7491;
                fill: #0a4256;

                path {
                    fill: #0a4256;
                    stroke: #8ea9b9;
                }

                .st42 {
                    fill: none;
                }

                .icon-outline {
                    fill: #fff;
                    stroke: #fff;
                    stroke-width: 2px;
                }
            }
        }

        .image-wrapper-label-text {
            &.selected {
                color: #3d7491;
            }
        }

        .step5-main-text {
            color: #3d7491;
        }

        .st25 {
            stroke: #767777
        }

        &.warranty-form {
            .phone-icon {
                rotate: 0deg;
                margin-right: 0.5rem;
            }
        }

        .discount-text {
            ul {
                list-style-type: none;
                padding-left: 0px;

                li {
                    &:first-child {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
