.single-step-estimate-lhws {
  background: #ccdde3;
  label {
    font-weight: 700;
  }
  .grecaptcha-badge { visibility: hidden; }

  .MuiPaper-elevation1 {
    margin: 0;
    width: 100%;
    box-shadow: none;
    background: transparent;
    padding: 40px !important;
  }

  .MuiContainer-root {
    padding-left: 0;
    padding-right: 0;
  }

  .phone-number {
      cursor: pointer;
      color: #02A780;
    }

  .recaptcha {
    margin-bottom: 20px;
  }

  .form-discount-box {
    padding: 10px;
    width: 100%;
    display: block;
    text-align: center;
    margin: 20px auto;
    border: 1px solid #02A780;
    font-weight: 500;
    color: #02A780;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 10px;
      svg {
        width: 20px;
        height: 20px;
        display: inline-block;
      }
    }
  }

  p {
    font-size: 1rem;
  }

  u {
    text-decoration: none;
  }
}
