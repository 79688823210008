.leafhome-forms {

  form {
    scroll-margin-top: 200px;
  }

  .render-owner-thankyou {
    scroll-margin-top: 400px;

    @media only screen and (max-width: 768px) {
      scroll-margin-top: 200px;
    }
  }

  .progress {
    --bs-progress-height: 4px;
  }

  .input-field {
    border: 1px solid #ADB5BD;
    border-radius: 8px;
    width: 100%;
    height: 48px;
    padding: 10px 14px;
    color: #0A0A0A;

    &::placeholder {
      color: #6C757D;
    }

    &:focus-visible {
      outline: 5px solid rgba(#2466FC, 0.4);
      border-color: transparent;
    }

    &.correct {
      border: 1.5px solid #198754;

      &:focus-visible {
        outline: 5px solid rgba(#198754, 0.4);
        border-color: transparent;
      }
    }

    &.error {
      border: 1.5px solid #D3223C;

      &:focus-visible {
        outline: 5px solid rgba(#D3223C, 0.4);
        border-color: transparent;
      }
    }
  }

  .error-msg {
    color: #D3223C;
    font-size: 14px;
    font-weight: 400 !important;
    margin-top: 4px;
  }

  label {
    margin-bottom: 7px;
    font-size: 14px;

    &.form-icon {
      width: 67px;
      margin-right: 13px;
    }
  }

  .image-wrapper-label-text {
    &.small {
      margin: 3px 0;
      font-size: 12px;
    }
  }

  fieldset {
    label {
      margin-bottom: 0;
    }
  }

  .form-disclaimer,
  p {
    color: #6c757d;
  }

  input[type='checkbox'] {
    accent-color: #0a0a0a;
    color: #000;
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin-right: 6px;
  }

  .divider {
    border: 0.75px solid #ADB5BD;
    margin: 16px 0;
    opacity: 1;
  }

  .MuiPaper-elevation1 {
    height: auto;
    padding: 52px 48px;
    box-shadow: none;
    border-radius: 0;
    background: transparent;

    @media only screen and (max-width: 768px) {
      padding: 16px 12px;
    }

    .MuiDialog-container & {
      background: #fff;
    }
  }

  .form-disclaimer,
  .form-disclaimer-text {
    font-size: 12px;
    color: #6C757D;
    margin-bottom: 0;
    font-weight: 300;
  }

  span.disclaimer {
    width: 19px;
    height: 18px;
    display: inline-block;
  }
}

.leafhome-close-icon {
  &:hover {
    background-color: transparent !important;
  }
}

.MuiDialog-container {
  .MuiDialog-paperScrollBody {
    max-width: 360px;
    border-radius: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);

    .leafhome-forms {
      .MuiPaper-elevation1 {
        padding: 20px !important;
        height: auto;

        @media only screen and (max-width: 768px) {
          padding: 16px !important;
        }
      }
    }
  }
}
