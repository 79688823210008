.career-form {
  padding: 30px 0;

  label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: left;
    color: #333;
  }

  .hide-referral {
    display: none;
  }

  .recaptcha {
    margin-bottom: 20px;
  }
}

// Styling for LHSS.
[data-brand="lhss"] {
  .career-form {
    .heading {
      font-size: 16px;
      font-weight: 400;
    }

    label {
      color: #3d7491;
    }

    .input-field {
      border: 1px solid #666766;

      &.error {
        border: 1px solid #ef643d;
      }

      &:focus {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
      }

      &.correct {
        border: 1px solid #666766;
      }
    }

    .message-wrap {

      &.installation-message-wrap {
        max-width: 385px;
      }

    }

    .optional {
      color: #333;
      font-weight: 400;
    }
  }
}
