.general-form {
  .service-support-form {
    background-color: #f4f4f4;
    padding: 30px 0;
  }

  .heading {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
  }

  .optional {
    margin: 0 5px;
  }

  .thankyou-wrap {

    p {
      font-size: 16px;
    }

    a {
      margin: 0px 5px;
    }
  }

  .message-wrap {

    p {
      font-size: 16px;
      margin-top: 25px;
    }

    a {
      margin: 0px 5px;
    }
  }
}

[data-brand=lhss] {

  .alert-message-wrap {
      background-color: #eef9ee;
      color: #768b78;
  }

}

[data-brand=lhws] {

  .service-support-form {
    background-color: #fff;

  }

}